import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

import { ListingDescriptionProps } from './models';

const ListingDescription: FC<ListingDescriptionProps> = ({ description }) => (
  <DangerouslySetInnerHtml
    data-testid="listing-description"
    element="p"
    className="listing-description"
    html={description}
  />
);

export default ListingDescription;
