import { ResponsiveType } from 'react-multi-carousel';
import {
  CAROUSEL_STATE_MOCK as CAROUSEL_STATE_MOCK_DEFAULT,
  DEFAULT_SM_PADDINGS as DEFAULT_SM_PADDINGS_DEFAULT,
} from 'gatsby-theme-husky/src/common/CustomCarousel/constants';
import { LAYOUT_BREAKPOINTS } from 'gatsby-theme-husky/src/utils/constants';

export const RESPONSIVE: ResponsiveType = {
  sm: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.md, min: 0 },
    items: 1,
    partialVisibilityGutter: 51,
  },
  xl: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.xl, min: LAYOUT_BREAKPOINTS.md },
    items: 2,
    slidesToSlide: 2,
  },
  lg: {
    breakpoint: { max: LAYOUT_BREAKPOINTS.xxl, min: LAYOUT_BREAKPOINTS.xl },
    items: 3,
    slidesToSlide: 3,
  },
  xxl: {
    breakpoint: { max: 100000, min: LAYOUT_BREAKPOINTS.xxl },
    items: 4,
    slidesToSlide: 4,
  },
};

export const DEFAULT_SM_PADDINGS = { ...DEFAULT_SM_PADDINGS_DEFAULT };
export const CAROUSEL_STATE_MOCK = { ...CAROUSEL_STATE_MOCK_DEFAULT };
